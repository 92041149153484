/**
 * @param {{
 *   date_from, date_to, auction_coeff
 * }} vip_dates
 * @param {string} from date from
 * @param {String} to date to
 */
function getVipDatesInRange(vip_dates, from, to) {
  const result = [];
  const date_start = new Date(from).getTime();
  const date_end = new Date(to).getTime();
  function addToResult({ auction_coeff }, from, to) {
    result.push({
      date_from: new Date(from).toISOString().slice(0, 10),
      date_to: new Date(to).toISOString().slice(0, 10),
      auction_coeff: auction_coeff,
    });
  }
  for (const el of vip_dates) {
    const vip_from = new Date(el.date_from).getTime();
    const vip_to = new Date(el.date_to).getTime();

    //if vip_from is inside range
    if (vip_from >= date_start && vip_from <= date_end) {
      addToResult(el, vip_from, Math.min(vip_to, date_end));
    }
    //if vip_to is inside range
    else if (vip_to <= date_end && vip_to >= date_start) {
      addToResult(el, Math.max(date_start, vip_from), vip_to);
    }
    // if range is inside vip
    else if (vip_from <= date_start && vip_to >= date_end) {
      addToResult(el, date_start, date_end);
    }
  }
  if (result.length == 0) {
    return [
      {
        date_from: null,
        date_to: null,
        auction_coeff: 0,
      },
    ];
  }
  return result;
}

export default getVipDatesInRange;
