<template>
  <b-input type="number" min="0" :value="percents" @input="(val) => sendValue(val)" @keypress="isNumber($event)"></b-input>
</template>
<script>
export default {
  name: 'AuctionInput',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    percents: {
      get() {
        return Math.round(this.value * 100);
      },
      set(val) {
        if (isNaN(val)) {
          this.$emit('input', 0);
        }
        let value = Math.round(parseInt(val)) / 100;
        this.$emit('input', value);
      },
    },
  },
  methods: {
    sendValue(val) {
      this.percents = val;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped></style>
