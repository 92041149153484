<template>
  <div class="d-flex flex-column gap-3 mt-3">
    <b-form-group class="mb-0">
      <b-form-checkbox v-model="is_vip" class="custom-control-inline" :disabled="disabled">
        {{ $t('vip.vipSettings') }}
      </b-form-checkbox>
    </b-form-group>

    <div class="d-flex w-100 justify-content-end align-items-center pb-2">
      <b-button :disabled="disabled || is_vip == false" data-automatic="add-row" @click="addRowToProjectVip">
        {{ $t('table.addRow') }}
      </b-button>
    </div>

    <table class="table-sm small w-100" style="table-layout: fixed">
      <thead>
        <tr>
          <th style="width: 20%">%</th>
          <th style="width: 60%">
            {{ $t('table.period') }}
          </th>
          <th style="width: 10%"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, index) in vip_dates" :key="index">
          <td style="width: 20%">
            <AuctionInput v-model="vip_dates[index].auction_coeff" :disabled="disabled || is_vip == false"> </AuctionInput>
          </td>
          <td style="width: 60%">
            <div class="d-flex">
              <datepicker-wrapper
                :id="'vip-date-from-' + index"
                v-model="vip_dates[index].date_from"
                :disabled="disabled || is_vip == false"
                required
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                class="mr-2"
                style="width: 50%"
                :min="minDate"
                :max="maxDate"
              />
              <datepicker-wrapper
                :id="'vip-date-to-' + index"
                v-model="vip_dates[index].date_to"
                :disabled="disabled || is_vip == false"
                required
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="getMaxDate(vip_dates[index].date_from, minDate)"
                :max="maxDate"
                style="width: 48%"
                class=""
              />
            </div>
          </td>
          <td style="width: 10%">
            <button :disabled="disabled || is_vip == false" class="btn-transparent trash-hover mx-2" type="button" @click="deleteRowToProjectVip(index)">
              <b-icon icon="trash" :title="$t('table.delete')"></b-icon>
            </button>
            <b-icon
              v-if="datesInvalid(vip_dates[index])"
              :title="`${$t('validations.periodMustBeInRange')}: ${minDate}, ${maxDate}`"
              icon="exclamation-circle"
              class="text-danger"
            ></b-icon>
          </td>
        </tr>
      </tbody>
    </table>

    <b-form-group v-if="limit != undefined" :label="$t('vip.limit_advertiser_in_auction_block')">
      <b-form-input style="max-width: 250px" v-model.number="limit_advertiser_in_auction_block" :disabled="disabled || !is_vip" type="number"></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import AuctionInput from '../AuctionInput.vue';
import DatepickerWrapper from '../DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
export default {
  name: 'VipSettings',
  components: {
    AuctionInput,
    DatepickerWrapper,
  },
  mixins: [getMinOrMaxDate],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: undefined,
    },
    isVip: {
      type: Boolean,
      required: true,
    },
    vipDates: {
      type: Array,
      required: true,
    },
    minOneRow: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: undefined,
    },
    maxDate: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    vip_dates: {
      get() {
        return this.vipDates;
      },
      set(value) {
        this.$emit('update:vipDates', value);
      },
    },
    limit_advertiser_in_auction_block: {
      get() {
        return this.limit;
      },
      set(value) {
        this.$emit('update:limit', value);
      },
    },
    is_vip: {
      get() {
        return this.isVip;
      },
      set(value) {
        this.$emit('update:isVip', value);
      },
    },
  },
  watch: {
    // is_vip(v) {
    //   if (!v) {
    //     this.vip_dates = [];
    //     this.limit_advertiser_in_auction_block = 0;
    //   } else if (this.vip_dates == 0) {
    //     this.vip_dates.push({
    //       date_from: '',
    //       date_to: '',
    //       auction_coeff: 0,
    //     });
    //   }
    // },
    vip_dates(v) {
      if (this.minOneRow && v.length == 0 && this.is_vip) {
        this.is_vip = false;
      }
    },
  },
  methods: {
    addRowToProjectVip() {
      this.vip_dates.push({
        date_from: '',
        date_to: '',
        auction_coeff: 0,
      });
    },
    deleteRowToProjectVip(index) {
      this.vip_dates.splice(index, 1);
    },
    datesInvalid(el) {
      if ((el.date_to && el.date_to > this.maxDate) || (el.date_from && el.date_from < this.minDate)) {
        return true;
      }
      return false;
    },
  },
};
</script>
